<template>
    <div
        data-scroll
        :data-scroll-id="name"
        :class="{ lightMode: lightMode }"
        class="slider-container"
    >
        <text-wipe
            id="history-wipe"
            :header="header"
            :color="shaderColor"
            :left="true"
        />
        <div id="draggable" class="draggable">
            <div
                id="draggable-slider"
                class="draggable-slider draggable-slider-history unselectable"
            >
                <div
                    v-for="(item, index) in slides"
                    @click="openSlide(index)"
                    :key="index"
                    :id="'slide' + index"
                    class="slide-item unselectable"
                >
                    <div class="image-container">
                        <img
                            :src="item.path"
                            class="item-image unselectable"
                            alt=""
                        />
                    </div>
                    <div
                        :class="{ 'not-title': !item.title }"
                        class="item-content"
                    >
                        <div v-if="item.title" class="dot-container">
                            <div class="dot"></div>
                            <span class="title">{{ item.title }}</span>
                        </div>
                        <div v-if="item.teaser" class="line"></div>
                        <span
                            v-if="item.teaser"
                            class="description"
                            :class="{ top: !item.title }"
                            >{{ item.teaser }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <portal to="destination">
            <history-scale-slider
                :activeSlideIndex="activeSlideIndex"
                :changeSlideIndex="changeSlideIndex"
                :slides="slides"
            />
        </portal>
    </div>
</template>

<script>
import textWipe from "@/components/custom/textWipe";
import drag from "@/scripts/draggableSlider";
import { mapActions, mapGetters } from "vuex";
import HistoryScaleSlider from "@/components/PageComponents/HistoryScaleSlider";

export default {
    props: {
        name: {
            type: String,
            default: ""
        },
        data: {
            type: [Object, Array],
            default: () => {}
        },
        config: {
            type: [Object, Array],
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeSlideIndex: 0
        };
    },
    components: {
        textWipe,
        HistoryScaleSlider
    },
    computed: {
        ...mapGetters({
            geDevice: "resize/geDevice",
            dragging: "drag/getDragging",
            historyOpened: "scaleComponent/getHistoryOpened"
        }),
        device() {
            return this.geDevice?.type;
        },
        slides() {
            return this.data?.list?.map(item => ({
                path: item.image?.[0]?.devices?.[this.device],
                teaser: item?.teaser,
                title: item?.slider_title,
                description: item?.description
            }));
        },
        header() {
            return this.config.header || {};
        },
        scaleDelta() {
            let delta = 75;
            if (this.geDevice.size < 1441) {
                delta = 30;
            }
            if (this.geDevice.size < 1025) {
                delta = 0;
            }
            return delta;
        },
        shaderColor() {
            return this.lightMode ? [1, 1, 1] : [0.039, 0.078, 0.11];
        }
    },
    methods: {
        ...mapActions({
            setHistoryOpened: "scaleComponent/setHistoryOpened"
        }),
        getPadding(width) {
            let padding = 195;
            if (width <= 1650) {
                padding = 165;
            }
            if (width <= 1439) {
                padding = 128;
            }
            if (width <= 1365) {
                padding = 47;
            }
            if (width <= 767) {
                padding = 16;
            }
            return padding;
        },
        initClickedItemAnimation({ right, top, width, height }) {
            let innerWidth = this.geDevice?.size;
            let innerHeight = this.geDevice.innerHeight;
            let newWidth = innerWidth - 2 * this.scaleDelta;
            let aspectRatio = height / width;
            let newHeight = newWidth * aspectRatio;
            let scaleX = newWidth / width;
            let scaleY = newHeight / height;
            let transformX = -(
                innerWidth -
                (right + width + (newWidth - width) / 2) -
                this.scaleDelta
            );
            let transformY =
                newHeight / 2 -
                top -
                height / 2 -
                this.scaleDelta +
                (innerHeight - newHeight) / 2;

            if (this.geDevice.size < 1025) {
                aspectRatio = width / height;
                newHeight = this.geDevice.innerHeight;
                newWidth = aspectRatio * newHeight;
                transformX =
                    -(
                        innerWidth -
                        (right + width + (newWidth - width) / 2) -
                        this.scaleDelta
                    ) -
                    (newWidth - innerWidth) / 2;
                transformY = newHeight / 2 - top - height / 2 + this.scaleDelta;
                scaleX = newWidth / width;
                scaleY = newHeight / height;
            }

            this.gsap.set(".history-scale-slider .content-container", {
                width: this.geDevice?.size < 1025 ? newWidth : newWidth / 2
            });
            this.gsap.to(".history-scale-slider .image-container", {
                duration: 1,
                clipPath: "inset(0px)",
                force3D: false,
                transform: `translate3d(${transformX}px,${transformY}px,0px) scale(${scaleX},${scaleY}) perspective(0)`,
                ease: this.CustomEase.create(
                    "custom",
                    "M0,0,C0.001,0,-0.001,0,0,0,0.798,0,0.205,1,1,1"
                ),
                onComplete: () => {
                    this.gsap.to(".history-scale-slider", {
                        background: "#0A141C",
                        pointerEvents: "auto"
                    });
                    this.gsap.to(".history-scale-slider .slider-hide", {
                        opacity: 1
                    });
                    this.gsap.to(".history-scale-slider .content-container", {
                        opacity: 1
                    });
                    this.gsap.to(".history-scale-slider .content-container", {
                        opacity: 1
                    });
                    this.gsap.to(".history-scale-slider .close-container", {
                        opacity: 1
                    });
                }
            });
        },
        changeSlideIndex(index) {
            this.activeSlideIndex = index;
        },
        openSlide(index) {
            if (this.dragging) return;
            this.setHistoryOpened(true);
            this.changeSlideIndex(index);
            let imageContainer = document.querySelector(
                `#slide${index} .image-container`
            );
            let image = document.querySelector(`#slide${index} .item-image`);
            let {
                width,
                height,
                right,
                top
            } = imageContainer.getBoundingClientRect();

            this.gsap.set(".history-scale-slider", {
                display: "flex",
                opacity: 1
            });
            this.gsap.set(".history-scale-slider .image-container", {
                width,
                height,
                right: this.geDevice?.size - right - this.scaleDelta,
                top: top - this.scaleDelta
            });

            this.gsap.set(".history-scale-slider .image-container .image", {
                width: image.style.width,
                height: image.style.height,
                // transform: image.style.transform,
                y: parseFloat(image.style.transform.split(",")[1])
            });

            this.initClickedItemAnimation({
                right: this.geDevice?.size - right,
                top,
                width,
                height
            });
        },
        slideAnimation() {
            let slideItems = document.querySelectorAll(
                `#draggable-slider.draggable-slider-history .slide-item`
            );
            // let itemImages = document.querySelectorAll(`#draggable-slider .slide-item .item-image`)

            slideItems.forEach((item, index) => {
                let fromLeftDistance =
                    item.getBoundingClientRect()?.left / innerWidth;
                let delta =
                    this.geDevice.size < 768
                        ? (index + 1) * 5
                        : (index + 1) * 15;
                //
                let posY = fromLeftDistance * 2 * delta;
                this.gsap.set(item, {
                    y: posY,
                    rotate: 0.01,
                    force3D: false
                });
                // this.gsap.set(itemImages[index], {
                //   y: posY / 3,
                //   force3D: false,
                // })
            });
        },
        resize() {
            if (this.historyOpened) {
                this.openSlide(this.activeSlideIndex);
            }
        },
        resizeHandler() {
            window.addEventListener("resize", this.resize);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.resizeHandler();
            setTimeout(() => {
                drag(
                    this.slideAnimation,
                    false,
                    true,
                    "draggable-slider",
                    this.getPadding
                );
            }, 300);
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resize);
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.slider-container /deep/ {
    margin: 144px auto 90px;
    top: 0;
    width: calc(100% - 390px);
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        width: calc(100% - 330px);
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        width: calc(100% - 256px);
    }
    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        width: calc(100% - 94px);
        margin-top: 89px;
        margin-bottom: 75px;
    }
    @media only screen and (max-width: 767px) {
        width: calc(100% - 32px);
        margin-top: 55px;
        margin-bottom: 24px;
    }

    .unselectable {
        outline: none;
        user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    .text-wipe {
        width: fit-content;
    }

    .draggable {
        cursor: grab;

        .draggable-slider {
            display: flex;
            align-items: center;
            margin-top: 93.5px;
            width: fit-content;
            @media only screen and (max-width: 1441px) and (min-width: 768px) {
                margin-top: 55.5px;
            }
            @media only screen and (max-width: 767px) {
                margin-top: 34px;
            }

            .slide-item {
                transform-origin: bottom;
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                outline: 1px solid transparent;
                will-change: transform;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0 !important;
                }

                @media only screen and (max-width: 1023px) {
                    margin-right: 15px;
                }

                .image-container {
                    overflow: hidden;
                    width: 750px;
                    clip-path: inset(100% 0% 0% 0%);
                    @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                        width: 540px;
                    }
                    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                        width: 450px;
                    }
                    @media only screen and (max-width: 1023px) and (min-width: 769px) {
                        height: 338px;
                        width: fit-content;
                    }
                    @media only screen and (max-width: 768px) and (min-width: 500px) {
                        width: fit-content;
                        height: 334px;
                    }
                    @media only screen and (max-width: 499px) {
                        height: 212px;
                        width: fit-content;
                    }

                    .item-image {
                        position: relative;
                        //height: 110%;
                        //top: -5%;
                        pointer-events: none;
                        width: 100%;
                        @media only screen and (max-width: 1023px) {
                            width: auto;
                            height: 100%;
                        }
                    }
                }

                .item-content {
                    position: relative;
                    top: 85px;
                    display: flex;
                    align-items: center;
                    z-index: 2;
                    will-change: transform;
                    backface-visibility: hidden;

                    @media only screen and (max-width: 1023px) and (min-width: 768px) {
                        top: 75px;
                    }
                    @media only screen and (max-width: 767px) {
                        top: 60px;
                    }

                    &.not-title {
                        top: 55px;
                        @media only screen and (max-width: 1023px) {
                            top: 65px;
                        }

                        .line {
                            margin-left: 30px;
                            @media only screen and (max-width: 1023px) {
                                margin-left: 20px;
                            }
                        }
                    }

                    .dot-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 74px;
                        @media only screen and (max-width: 1680px) and (min-width: 768px) {
                            margin-left: 20px;
                        }
                        @media only screen and (max-width: 767px) {
                            margin-left: 0;
                        }

                        .dot {
                            @include circle(10);
                            background: #e5b676;
                            margin-right: 12px;
                            @media only screen and (max-width: 767px) {
                                margin-right: 10px;
                            }
                        }

                        .title {
                            font-size: 34px;
                            font-style: italic;
                            letter-spacing: 1.3px;
                            font-weight: normal;
                            color: #e5b676;

                            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                                font-size: 21px;
                            }
                            @media only screen and (max-width: 769px) {
                                font-size: 16px;
                            }
                        }
                    }

                    .line {
                        width: 1px;
                        height: 120px;
                        background: #e5b676;
                        margin: -60px 12px 0;
                        @media only screen and (max-width: 1366px) and (min-width: 1024px) {
                            margin: -50px 12px 0;
                        }
                        @media only screen and (max-width: 1023px) and (min-width: 768px) {
                            margin: -40px 12px 0;
                        }
                        @media only screen and (max-width: 767px) {
                            margin: -40px 10px 0;
                        }
                    }
                }
            }

            .description {
                transform: translateY(50%);
                font-size: 16px;
                font-style: italic;
                font-weight: normal;
                line-height: 20px;
                color: white;
                transition: background 0.5s ease-out;
                text-align: left;

                &.top {
                    transform: translateY(-10%);
                }

                @media only screen and (max-width: 1441px) {
                    transform: translateY(20%);
                }
                @media only screen and (max-width: 1023px) {
                    font-size: 16px;
                    transform: translateY(30%);
                }
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                    text-align: left;
                    transform: translateY(30%);
                }
            }
        }
    }
}

.slider-container.lightMode /deep/ {
    .text-wipe {
        .header {
            color: #0a141c;

            &.colored {
                color: #e5b676;
            }
        }
    }

    .draggable {
        .draggable-slider {
            .description {
                color: #0a141c;
            }
        }
    }
}
</style>
